// IMPORT MOTORS
import React from 'react';

const Banner = (props) => {


    return (
        <div className="banner">

            <img className='imgBanner' src={props.name}/>

        </div>
    )
}

export default Banner;