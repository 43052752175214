import React, {useState} from 'react';
import Link from '../Link/link';
import {useNavigate} from 'react-router-dom'; 
import menu from '../../assets/icos/navico.png';

import { useTranslation } from "react-i18next";

const Nav = () => {

    const [language, setLanguage] = useState('es');

    const [t, i18n] = useTranslation("global");

    let navigate = useNavigate();

    const ChangeLanguage = (value) => {
        if (value == 'es') {
            i18n.changeLanguage('en');
            setLanguage('en');
        } else {
            i18n.changeLanguage('es');
            setLanguage('es');
        }        
    }

    return (
        <div className='navContainer'>

            <div id="navbar">

                <a className="nombre hoverLinks" onClick={()=>navigate('/')}>
                    {t("Nav.TitleF")}
                </a>

                <div className='rightNav'>
                    <div id="desplegable" >
                        <img id="menu" src={menu} alt="Enlaces" onClick=""/>

                        <ul id="navLinkBox" className="linksContainer" >
                            <li className="liLinks hoverLinks"><Link to="/gallery" name={t("Nav.Gallery")}/></li>
                            <li className="liLinks hoverLinks"><Link to="/actress" name={t("Nav.Actress")}/></li>
                            <li className="liLinks hoverLinks"><Link to="/screenwriter" name={t("Nav.ScreenWriter")}/></li>
                            <li className="liLinks hoverLinks"><Link to="/vo" name={t("Nav.Vo")}/></li> 
                            <li className="liLinks hoverLinks"><Link to="/contact" name={t("Nav.Contact")}/></li>
                            <li className="liLinks hoverLinks"><a className="navLinkText" target="_blank" href="https://www.imdb.com/name/nm8379596/">IMDB</a></li>
                            <li className="liLinks hoverLinks"><a className="navLinkText" target="_blank" href="https://www.instagram.com/lauracandelaactriz/">IG</a></li>
                        </ul>
                    </div>
                <div className={`iconSize ${language}`} onClick={()=>ChangeLanguage(language)}></div>
                </div>
            </div>

        </div>
    )
}

export default Nav;