// IMPORT MOTORS
import React, {useEffect, useState} from 'react';
import Banner from '../../components/Banner/banner';
import imgBanner from '../../assets/img/slider-pagina-publicidad.png';
import voImage from '../../assets/img/Laura-Candela-Micro.jpg';
import { useTranslation } from "react-i18next";

const Ads = (props) => {

    const [sizeVid, setSizeVid] = useState(["640px", "480px"]);
    const [sizeSmallVid, setSizeSmallVid] = useState(["440px", "260px"]);
    const [reelArray, setReelArray] = useState([]);
    const [vArray, setVArray] = useState([]);

    const [t] = useTranslation("global");

    useEffect(()=>{
        getVArray();
        getReelArray();
    },[]);

    const getReelArray = () => {
        setReelArray([
            "https://player.vimeo.com/video/521908508?h=fe5183deff",
            "https://player.vimeo.com/video/521887751?h=c34557b50d",
            "https://player.vimeo.com/video/521884966?h=381ff7cb8a"
        ])
    }

    const getVArray = () => {
        setVArray([
            "https://player.vimeo.com/video/329154462?h=3970cac14c",
            "https://player.vimeo.com/video/386662398?h=e479502070",
            "https://www.youtube.com/embed/tAC73QRy-Yw",
            "https://www.youtube.com/embed/HOWx4vhl1Xg",
            "https://www.youtube.com/embed/5rCgeJEJ5Ow",
            "https://www.youtube.com/embed/9S5z3CTBPWU",
            "https://www.youtube.com/embed/0VVB9Fu6xLI",
            "https://www.youtube.com/embed/WcQoWacslJM",
            "https://www.youtube.com/embed/Ac8J--71zQc",
        ])
    }

    return (
        <div className="globalContainer">

            <Banner name={imgBanner}/>

            <section className='globalSection'>
                
                <h3 className="sectionTitle">REELS</h3>
                            
                <iframe className='vimeos' title="Laura Candela Reel" src="https://player.vimeo.com/video/672816615?h=83268bff13" width="90%" height={sizeVid[1]} frameborder="0" allowfullscreen></iframe>
                
                <div className='videoContainer'>
                    {reelArray.map((routes, index)=>(
                        <iframe className='vimeos' title="Laura Candela Reel" key={index} src={routes} width="90%"  height={sizeSmallVid[1]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        ))}                
                </div>

                <h3 className="sectionTitle">{t("Nav.Vo")}</h3>

                <div className='videoContainer'>
                    {vArray.map((routes, index)=>(
                        <iframe className='vimeos' title="Laura Candela Voice Over" key={index} src={routes}  height={sizeVid[1]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    ))}
                </div>

            </section>

            <div className='dataContainer brief'>
                    
                    <img className="dataImage" src={voImage} alt="Laura Candela"/>

                    <div className='briefP'>
                        <h3 className='dataTitle'>{t("LocutionTitle")}</h3>
                        
                        <p className='paragraf'>{t("LocutionText1")}</p>
                        <p className='paragraf'>{t("LocutionText2")}</p>
                        <p className='paragraf'>{t("LocutionText3")}</p>
                    </div>

                    
                </div>

        </div>
    )
}

export default Ads;