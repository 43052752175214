// IMPORT MOTORS
import React, {useEffect, useState} from 'react';
import Banner from '../../components/Banner/banner';
import imgBanner from '../../assets/img/slider-pagina-actriz2.png';
import writerImage from '../../assets/book/Laura-Candela-11.jpg';
import { useTranslation } from "react-i18next";
import cvPdf from '../../assets/cv/Laura-Candela-CV.pdf';

const Actress = (props) => {

    const [sizeVid, setSizeVid] = useState(["640px", "360px"]);
    const [sizeSmallVid, setSizeSmallVid] = useState(["440px", "480"]);
    const [reelArray, setReelArray] = useState([]);
    const [vArray, setVArray] = useState([]);

    const [t] = useTranslation("global");

    useEffect(()=>{
        getVArray();
    },[]);

    
    const getVArray = () => {
        setVArray([
            "https://www.youtube.com/embed/qt_syihgwyc",
            "https://www.youtube.com/embed/lJ1hgas_NX4",
            "https://www.youtube.com/embed/HZmT-ysYBTA",
            "https://www.youtube.com/embed/jQ1gWwqx0Hc"
        ])
    }

    return (
        <div className="globalContainer">

            <Banner name={imgBanner}/>

            <section className='globalSection'>

                    <h3 className="sectionTitle">{t("ActressTitle")}</h3>

                    
                    <iframe className='vimeos' title="Laura Candela Reel" src="https://www.youtube.com/embed/PT-vjO-g96g" width="90%" height={sizeSmallVid[1]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                    <h3 className="sectionTitle">{t("Scene")}</h3>

                    <div className='videoContainer'>
                        {vArray.map((routes, index)=>(
                            <iframe className='vimeos' title="Laura Candela Voice Over" key={index} src={routes} width="90%" height={sizeSmallVid[1]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        ))}
                    </div>

                    <a className='cvText' target="_blank" href={cvPdf}>CV</a>

                    {/* <div className='cvContainer'>
                        <div className='cvSection'>
                            <h3 className='cvTitleSection'>TEATRO</h3>

                            <h4 className='cvTitle'>“Welcome to Royonair”</h4>
                            <p className='cvText'>Microteatro Valencia, dirigit por Miracle y Laura Candela.</p>

                            <h4 className='cvTitle'>“Voices of Europe”</h4>
                            <p className='cvText'>Stella Polaris, Sandefjord, Norway.</p>

                            <h4 className='cvTitle'>“Tres Mujeres y ¿Un destino?”</h4>
                            <p className='cvText'>Teatro y Compañía. (Teatro Arenal, Madrid. Teatre Neu, Barcelona, Teatro Lara, Madrid).</p>
                            <h4 className='cvTitle'>“Los bichitos de luz”</h4>
                            <p className='cvText'>Dirigido por Esteban Hirschorn, compañía El Descompuesto.</p>

                            <h4 className='cvTitle'>“Con Amor y Sordidez”</h4>
                            <p className='cvText'>Compañía In Vittu dirigido por Johana Pérez Strand</p>

                            <h4 className='cvTitle'>“Ascenso a la fama”</h4>
                            <p className='cvText'>Microteatro Madrid 2018 dir. Miracle Candela</p>
                        </div>

                        <div className='cvSection'>
                            <h4 className='cvTitle'>“Triple Take”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Cortometraje de Brilliant Films</p>

                            <h4 className='cvTitle'>“Under Falls”</h4>
                            <p className='cvCharacter'>Sophia</p>
                            <p className='cvText'>TEASER dir. Andrés Grueiro.</p>

                            <h4 className='cvTitle'>“Together Alone”</h4>
                            <p className='cvCharacter'>Voz niña</p>
                            <p className='cvText'>Cortometraje dir. Ferrán Brooks.</p>

                            <h4 className='cvTitle'>“Edgar”</h4>
                            <p className='cvCharacter'>Amalia</p>
                            <p className='cvText'>Largometraje dir. Guillermo Barreira</p>

                            <h4 className='cvTitle'>“Dossier Negro, de Blasco Ibañez”</h4>
                            <p className='cvCharacter'>Prosituta y Fantasma</p>
                            <p className='cvText'>Teaser dir. Ferrán Brooks</p>

                            <h4 className='cvTitle'>“Sole”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Corto dir. Samuel García Chaparro</p>

                            <h4 className='cvTitle'>“El Rey de Amarillo”</h4>
                            <p className='cvCharacter'>Bruja</p>
                            <p className='cvText'>Teaser dir. Guillermo Barreira</p>

                            <h4 className='cvTitle'>“73”</h4>
                            <p className='cvCharacter'>Policía y voz noticias radio</p>
                            <p className='cvText'>Largometraje dir. Jose Pozo</p>

                            <h4 className='cvTitle'>“La Última Cena”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Cortometraje dir. Las Candela</p>

                            <h4 className='cvTitle'>“Pasión”</h4>
                            <p className='cvCharacter'>Co-protagonista</p>
                            <p className='cvText'>Cortometraje dir. Las Candela.</p>
                            
                            <h4 className='cvTitle'>“Sed”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>CortOmetraJe dir. Carlos Santiago</p>
                            
                            <h4 className='cvTitle'>“Juego de Muñecas”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Cortometraje dir. Miracle Candela</p>
                            
                            <h4 className='cvTitle'>“14 de febrero”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Cortometraje dir. Alfonso Martinez</p>
                            
                            <h4 className='cvTitle'>“The ultimate hideaway”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Cortometraje / teaser dir. juanjo bargues / Producción Anahuac Films.</p>
                            
                            <h4 className='cvTitle'>“No me ames”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Cortometraje dir. Miracle Candela</p>

                            <h4 className='cvTitle'>“All Fire Up”</h4>
                            <p className='cvCharacter'>Co-Protagonista</p>
                            <p className='cvText'>Cortometraje dir. Miracle Candela</p>
                            
                            <h4 className='cvTitle'>“Sola en casa”</h4>
                            <p className='cvCharacter'>Co-Protagonista</p>
                            <p className='cvText'>Cortometraje dir. Jaques Fletcher / Producción Odessa Films.</p>

                            <h4 className='cvTitle'>“La Familia”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Cortometraje dir. Miracle Candela</p>
                            
                            <h4 className='cvTitle'>“Namasté”</h4>
                            <p className='cvCharacter'>Protagonista</p>
                            <p className='cvText'>Cortometraje dir. Miracle Candela</p>
                            
                        </div>


                        <div className='cvSection'>
                            <h4 className='cvTitle'></h4>
                            <p className='cvText'></p>
                        </div>
                    </div> */}

            </section>

            <div className='dataContainer brief'>
                    
                    <img className="dataImage" src={writerImage} alt="Laura Candela"/>

                    <div className='briefP'>
                        <h3 className='dataTitle'>{t("ActressTitle")}</h3>
                        
                        <p className='paragraf'>{t("ActressText1")}</p>
                        <p className='paragraf'>{t("ActressText2")}</p>
                        <p className='paragraf'>{t("ActressText3")}</p>
                    </div>
                    
                    
                    
                </div>
        </div>
    )
}

export default Actress;