// IMPORT MOTORS
import React, {useEffect, useState} from 'react';
import Banner from '../../components/Banner/banner';
import imgBanner from '../../assets/img/slider-pagina-writer.jpg';
import writerImage from '../../assets/book/Laura-Candela-8.jpg';
// import Animadversion from '../../assets/plays/Animadversion-Laura-Candela.jpg';
// import RiseToFame from '../../assets/plays/Ascenso-A-La-Fama-Laura-Candela.jpg';
// import Namaste from '../../assets/plays/Namaste-Laura-Candela.jpg';
// import WelcomeToRoyonair from '../../assets/plays/Royonoir-Laura-Candela.jpg';
// import ThreeWomen from '../../assets/plays/Tres-Mujeres-Laura-Candela.jpg';
// import OraProNobis from '../../assets/plays/Tres-Mujeres-Laura-Candela.jpg';


import { useTranslation } from "react-i18next";

const Writer = () => {

    const [imageBook, setImageBook] = useState([]);
    const [t] = useTranslation("global");

    useEffect(()=>{
        getImages();
    },[]);

    const importAll = (r) => {
        let images = [];
        r.keys().map((item, index) => { images.push(r(item)) });
        return images;
    }

    const getImages = () => {
        const images = importAll(require.context('../../assets/plays', false, /\.(png|jpe?g|svg)$/));
        setImageBook(images);
    }

    const playsArray = ["Animadversion", "RiseToFame", "Namaste", "WelcomeToRoyonair", "ThreeWomen"];

    return (
        <div className="globalContainer">
            
            <Banner name={imgBanner}/>

            <section className='globalSection'>
            
                <h3 className="sectionTitle">{t("WriterTitle")}</h3>

                <div className='playsContainer'>
  
                    {playsArray.map((playName, index) => (
                        <div className='playsBox'>
                            <img className="photosBook" src={imageBook[index]} key={index} alt="Laura Candela Book"/>
                            <div className='playsBoxText'>
                                <div className='playsTitle'>
                                    <h3 className='dataTitle'>{t(`Plays.${playName}.Title`)}</h3>
                                </div>
                                <p className='paragraf'>{t(`Plays.${playName}.Brief`)}</p>
                            </div>
                        </div>
                    ))}

                </div>

            </section>

            <div className='dataContainer brief'>
                    
                    <img className="dataImage" src={writerImage} alt="Laura Candela"/>

                    <div className='briefP'>
                        <h3 className='dataTitle'>{t("WriterTitle")}</h3>
                        
                        <p className='paragraf'>{t("WriterText1")}</p>
                        <p className='paragraf'>{t("WriterText2")}<a target="_blank" rel="noreferrer" className="linkText" href="https://www.edav.es/es/guionistes/sales-candela-laura/">EDAV</a> {t("WriterText3")}</p>
                    </div>

                    
                </div>
        </div>
    )
}

export default Writer;