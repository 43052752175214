// IMPORT MOTORS
import React from 'react';

const ShadowBanner = () => {

    return (
        <div className="shadowbanner"></div>
    )
}

export default ShadowBanner;