// IMPORT MOTORS
import React from 'react';
import Banner from '../../components/Banner/banner';
import briefImage from '../../assets/book/Laura-Candela-6.jpg';
import imgBanner from '../../assets/img/Laura-Candela-PortadaFull.jpg';


import { useTranslation } from "react-i18next";

const Home = () => {

    const [t] = useTranslation("global");

    return (
        <div className="globalContainer">

                <Banner name={imgBanner}/> 
            
            <section className='globalSection'>
                
                <div className='homeIntro'>
                    <h3 className="sectionTitle">{t("About")}</h3>
                    <div className="line"></div>
                    <span className='textUrgent'>{t("ShortBrief")}</span>   
                </div>
                
                             

            </section>

            <div className='dataContainer brief'>
                <img className="dataImage" src={briefImage} alt="Laura Candela"/>
                <div className='briefP'>
                        <h3 className='dataTitle'>{t("LongBriefTitle")}</h3>                        
                        <p className='paragraf'>{t("LongBrief1")}</p>
                        <p className='paragraf'>{t("LongBrief2")}</p>
                        <p className='paragraf'>{t("LongBrief3")}</p>
                        <p className='paragraf'>{t("LongBrief4")}</p>
                        <p className='paragraf'>{t("LongBrief5")}<a target="_blank" rel="noreferrer" className="linkText" href="https://brossontalent.com/D/product/laura-candela/">Brosson Talent.</a> {t("LongBrief6")}</p>                        
                </div>
            </div>   
        </div>
    )
}

export default Home;