// IMPORT MOTORS
import React, {useEffect, useState} from 'react';
import Banner from '../../components/Banner/banner';
import imgBanner from '../../assets/img/slider-pagina-book.png';
// import im from '../../assets/book/Laura-Candela-1.jpg';
import { useTranslation } from "react-i18next";

const Book = () => {

    useEffect(()=>{
        getImages();
    },[]);

    const [t] = useTranslation("global");

    const [imageBook, setImageBook] = useState([]);

    const [classImage, setClassImage] = useState("noPic");
    const [imageContainer, setImageContainer] = useState("picContainer");
    const [indexImage, setIndexImage] = useState();

    // const importAll = (r) => {
    //     let images = [];
    //     r.keys().map((item, index) => { images.push(images[item.replace('./', '')] = r(item)); });
    //     return images;
    //   }

    const importAll = (r) => {
        let images = [];
        r.keys().map((item, index) => { images.push(r(item)) });
        return images;
      }

    const getImages = () => {
        const images = importAll(require.context('../../assets/book', false, /\.(png|jpe?g|svg)$/));
        setImageBook(images);
    }
    
    const Modal = (keyV) => {
        setClassImage("bigPicContainer");
        setImageContainer("noPic");
        setIndexImage(keyV);
    }

    const CloseModal = () => {
        setClassImage("noPic");
        setImageContainer("picContainer");
    }

    const PlusSlides = (control) => {

        let val = indexImage;

        switch (control) {
            case 1:
                if (indexImage+1 === imageBook.length)
                {
                    val = -1;
                }
                setIndexImage(val+1);
                break;
            case -1:
                if (indexImage === 0)
                {
                    val = parseInt(imageBook.length)-1;
                }
                setIndexImage(val-1);
                break;
            default:
                setIndexImage(0);
                break;
        }
    }

    return (
        <div className="globalContainer">

            <Banner name={imgBanner}/>

            <section className="globalSection">

                <h3 className="sectionTitle">{t("Laura Candela Book")}</h3>

                <div className={classImage}>
                        <span class="picActions pointer prev" onClick={()=>PlusSlides(-1)}>&#10094;</span>
                        <span class="picActions pointer next" onClick={()=>PlusSlides(1)}>&#10095;</span>
                        <span class="picActions close" onClick={()=>CloseModal()}>&times;</span>
                        <img className="bigPic" src={imageBook[indexImage]} alt="Laura Candela Book"/>
                    </div>

                <div className={imageContainer}>
                    {imageBook.map((staticRoute, index)=>(
                            <img className="photosBook" src={imageBook[index]} key={index} alt="Laura Candela Book" onClick={()=>Modal(index)}/>
                    ))}
                </div>

            </section>
        </div>
    )
}

export default Book;