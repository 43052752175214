import { useTranslation } from "react-i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { init, sendForm } from '@emailjs/browser';
import Banner from '../../components/Banner/banner';
import imgBanner from '../../assets/img/slider-pagina-writer.jpg';

const Contact = () => {
  const { register, handleSubmit } = useForm();
  const handleRegistration = (data) => console.log(data);

  const [t] = useTranslation("global");

    // init('op-bEmNqbabpo_lB0');

  const sendEmail = (e) => {
      console.log(e.target)
    e.preventDefault(); // Prevents default refresh by the browser
    sendForm('service_tw28tvg', 'template_jfzp9mq', e.target,'op-bEmNqbabpo_lB0')
    .then((result) => {
        alert("Mensaje enviado, contestaremos con la mayor brevedad posible", result.text);
    },
    (error) => {
        alert("Ha ocurrido un error, por favor inténtelo de nuevo", error.text);
    });
    e.target.reset()
        alert("Mensaje enviado");
};

  return (
    <div className="globalContainer">
            
        <Banner name={imgBanner}/>

        <section className='globalSection'>

            <h3 className="sectionTitle">{t("Nav.Contact")}</h3>

        {/* <form onSubmit={handleSubmit(handleRegistration)}>
          <div>
            <label>{t("ContactForm.Name")}</label>
            <input type="text" name="name" {...register('name', { required: "Name is required" })} />
          </div>
          <div>
            <label>{t("ContactForm.Subject")}</label>
            <input type="text" name="subject" {...register('subject', { required: "Subject is required" })} />
          </div>
          <div>
            <label>Email</label>
            <input type="email" name="email" {...register('email', { required: "Email is required" })} />
          </div>
          <div>
            <label>{t("ContactForm.Phone")}</label>
            <input type="number" name="phone" {...register('phone')} />
          </div>
          <div>
            <label>{t("ContactForm.Message")}</label>
            <textarea type="text" name="message" {...register('message')} />
          </div>
          <button>Submit</button>

    </form> */}

        <form method="post" id="contact-form" className="formBox" onSubmit={sendEmail}>
            <label for="from_name">{t("ContactForm.Name")}</label>
            <input className="contactInput" type="text" name="from_name" placeholder={t("ContactForm.Name")} required=""/>
            <label for="subject">{t("ContactForm.Subject")}</label>
            <input className="contactInput" type="text" name="subject" placeholder={t("ContactForm.Subject")} required=""/>
            <label for="from_email">Email</label>
            <input className="contactInput" type="email" name="from_email" placeholder="ejemplo@correo.com" required=""/>
            <label for="phone">{t("ContactForm.Phone")}</label>
            <input className="contactInput" type="text" name="phone" placeholder={t("ContactForm.Phone")} required=""/>
            <label for="message">{t("ContactForm.Message")}</label>
            <textarea className="contactInput" name="message" placeholder={t("ContactForm.Message")} required></textarea>
            <button id="submit" type="submit" className="dinC txtGreen" name="submit" value="ENVIAR">Enviar</button>			
        </form>
    </section>
</div>
  );
};
export default Contact;