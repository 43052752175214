
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './containers/Home/home';
import Gallery from './containers/Gallery/gallery';
import Writer from './containers/Writer/writer';
import Vo from './containers/Vo/vo';
import Actress from './containers/Actress/actress';
import Contact from './containers/Contact/contact';
import Nav from './components/Nav/nav';
import ShadowBanner from './components/ShadowBanner/shadowbanner';

import './App.scss';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <ShadowBanner />
        <Routes>

            <Route path="/" element={<Home/>}></Route>
            <Route path="/screenwriter" element={<Writer/>}></Route>
            <Route path="/vo" element={<Vo/>}></Route>
            <Route path="/actress" element={<Actress/>}></Route>
            <Route path="/gallery" element={<Gallery/>}></Route>
            <Route path="/contact" element={<Contact/>}></Route>
        
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
