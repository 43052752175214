import {useNavigate} from 'react-router-dom';

const Link = (props) => {

    let navigate = useNavigate();

    return (
        <div className="navLinkText" onClick={()=>navigate(props.to)}>
            {props.name}
        </div>
    )

}

export default Link;